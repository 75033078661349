import React from "react"
import theme from "theme"
import { Theme, Text, Link, Box, Section } from "@quarkly/widgets"
import { Helmet } from "react-helmet"
import { GlobalQuarklyPageStyles } from "global-page-styles"
import { RawHtml } from "@quarkly/components"
import * as Components from "components"
export default () => {
  return (
    <Theme theme={theme}>
      <GlobalQuarklyPageStyles pageUrl={"contact"} />
      <Helmet>
        <title>Uvixena Agence de marketing</title>
        <meta
          name={"description"}
          content={"Créer votre empreinte numérique, un pixel à la fois"}
        />
        <meta property={"og:title"} content={"Uvixena Agence de marketing"} />
        <meta
          property={"og:description"}
          content={
            "Şampiyonların Antrenman Yaptığı Yer - Tenis Oyununda Bizimle Ustalaşın"
          }
        />
        <meta
          property={"og:image"}
          content={"https://uvixena.com/img/1.jpg"}
        />
        <link
          rel={"shortcut icon"}
          href={"https://uvixena.com/img/2464787.png"}
          type={"image/x-icon"}
        />
        <link
          rel={"apple-touch-icon"}
          href={"https://uvixena.com/img/2464787.png"}
        />
        <link
          rel={"apple-touch-icon"}
          sizes={"76x76"}
          href={"https://uvixena.com/img/2464787.png"}
        />
        <link
          rel={"apple-touch-icon"}
          sizes={"152x152"}
          href={"https://uvixena.com/img/2464787.png"}
        />
        <link
          rel={"apple-touch-startup-image"}
          href={"https://uvixena.com/img/2464787.png"}
        />
        <meta
          name={"msapplication-TileImage"}
          content={"https://uvixena.com/img/2464787.png"}
        />
      </Helmet>
      <Components.Header />
      <Section padding="80px 0 80px 0">
        <Box
          display="flex"
          align-items="center"
          flex-direction="column"
          justify-content="center"
          padding="0px 200px 0px 200px"
          lg-padding="0px 0px 0px 0px"
        >
          <Text
            margin="0px 0px 30px 0px"
            font="--headline1"
            color="--primary"
            text-align="center"
            sm-font='normal 700 62px/1.2 "Source Sans Pro", sans-serif'
          >
            Contact avec nous
          </Text>
          <Text
            margin="0px 0px 48px 0px"
            font="--lead"
            color="#111111"
            text-align="center"
          >
            Prêt à élever votre marque ? Commençons ce voyage ensemble. Prenez
            contact avec nous par les canaux suivants, et faisons en sorte que
            la magie opère.
          </Text>
          <Link
            href="mailto:blank?contact@uvixena.com"
            text-align="center"
            color="#111111"
            font="--headline3"
            text-decoration-line="initial"
            margin="0px 0px 16px 0px"
          >
            contact@uvixena.com
          </Link>
          <Link
            href="tel:+33 4 74 40 60 79"
            text-align="center"
            color="#111111"
            font="--headline3"
            text-decoration-line="initial"
            margin="0px 0px 16px 0px"
          >
            +33 4 74 40 60 79
          </Link>
          <Text
            margin="0px 0px 48px 0px"
            font="--headline3"
            color="#111111"
            text-align="center"
          >
            Centre Commercial de, Rue Dr Bouchut, 69003 Lyon, France
          </Text>
        </Box>
      </Section>
      <Components.Footer />
      <RawHtml>
        <style place={"endOfHead"} rawKey={"65323428c5fd0f0020b15081"}>
          {
            ":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"
          }
        </style>
      </RawHtml>
    </Theme>
  )
}
